<template>
	<div class="app-main__inner">
		<div class="container rounded md:p-3">
			<div class="row no-gutters">
				<div class="col-md-12 col-lg-8 h-full">
					<div data-cy='count-cards' class="row mb-3 dm-sans-font mb-5">
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-blue rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-20">{{ $t('totalOrderToday') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-orders-today">{{
											$services.helpers.animateValue('total-orders-today', 0, GET_DASHBOARD_METRICS.total_orders_today || 0, 2000)
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-yellow border-blue-400 rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-20">{{ $t('totalOrderMonth') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-orders-month">{{
											$services.helpers.animateValue('total-orders-month', 0, GET_DASHBOARD_METRICS.total_orders_this_month || 0, 4000)
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-blue rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-32">{{ $t('totalCustomerVisit') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="total-customers">{{
											$services.helpers.animateValue('total-customers', 0, GET_DASHBOARD_METRICS.total_customer_visit_today || 0, 1000)
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 col-xl-3 mb-3">
							<div class="card flex flex-col p-1 bg-theme-green rounded-lg r-min-h">
								<div class="flex justify-center mt-2 mb-2">
									<img src="./../../assets/images/icons/money.png" alt="">
								</div>
								<div class="flex justify-center text-center text-white text-xs"><span
									class="w-32">{{ $t('averageOrderAmount') }}</span></div>
								<div class="text-center mb-3 lg:bottom-0 lg:absolute lg:w-full">
									<span class="text-4xl text-white font-semibold" id="average-order">{{
											$services.helpers.animateValue('average-order', 0, GET_DASHBOARD_METRICS.average_order_amount || 0, 1000)
										}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12" v-loading='loadingGraph'>
							<div class="bg-white shadow-md rounded-md px-2 pt-2 h-full">
								<div class="row top-section-bar">
									<div class="col-md-9">
										<h3 class="text-gray-900 text-xl font-semibold">{{ $t('totalRevenue') }}</h3>
									</div>
									<div class="col-md-3 float-right">
										<div class="flex justify-end space-x-3">
											<div class="w-full flex p-2">
												<el-date-picker
													size="mini"
													@change='onChangeGraphData'
													v-model="dateObject.date"
													type="daterange"
													format="yyyy-MM-dd"
													value-format="yyyy-MM-dd"
													range-separator="|">
												</el-date-picker>
											</div>
										</div>
									</div>
								</div>
								<div data-cy='graph' class="apex-chart-canvas dm-sans-font"
									 v-if="graphData.series.length > 0">
									<apexchart type="bar" height="300" :options="options"
											   :series="graphData.series"></apexchart>
									<!-- <apexchart type="bar" height="390" :options="options" :series="graphData.series"></apexchart> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-4">
					<div class="card flex flex-col p-3 justify-between md:mx-4 card-side-4 h-full">
						<div class="flex flex-col text-white">
							<span class="text-white text-xl tracking-wide">{{ $t('bestSellingArticle') }}</span>
							<ul style="list-style-type:disc !important" class="pl-3 mb-3">
								<li v-for="article in GET_DASHBOARD_METRICS.best_x_selling_articles"
									:key="uid(article)">{{ article.description }}
								</li>
							</ul>
							<hr class="border-gray-700">
						</div>
						<div class="flex flex-col text-white">
							<span>{{ $t('totalInvitedCustomers') }}</span>
							<span class="text-white text-2xl font-semibold" id="total-invited-cus">{{
									$services.helpers.animateValue('total-invited-cus', 0, GET_DASHBOARD_METRICS.total_invited_customers, 4000)
								}}</span>
							<hr class="border-gray-700 mt-3">
						</div>
						<div class="flex flex-col text-white">
							<span>{{ $t('totalPublishArticle') }}</span>
							<span class="text-white text-2xl font-semibold" id="total-publish-articles">{{
									$services.helpers.animateValue('total-publish-articles', 0, GET_DASHBOARD_METRICS.total_published_articles, 4000)
								}}</span>
							<hr class="border-gray-700 mt-3">
						</div>
						<div class="flex flex-col text-white">
							<span>{{ $t('totalSalesMonth') }}</span>
							<span class="text-white text-2xl font-semibold" id="total-sales">{{
									$services.helpers.animateValue('total-sales', 0, GET_DASHBOARD_METRICS.total_sales_this_month, 4000)
								}}</span>
							<hr class="border-gray-700 mt-3">
						</div>
						<div class="flex flex-col text-white">
							<span class="text-white tracking-wide">{{ $t('customerHighestPurchase') }}</span>
							<div class="flex justify-between"
								 v-for="customer in GET_DASHBOARD_METRICS.customer_with_high_purchase.slice(0, 4)"
								 :key="uid(customer)">
								<span class="block">{{ customer.customer_name }}</span>
								<!-- <span>{{ customer.customer_number }}</span> -->
								<span class="block">{{ customer.total }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {DatePicker} from 'element-ui'
import {mapGetters} from 'vuex'
import {getCompanyId} from "../../services/storage-window";

Vue.use(DatePicker)

export default {
	name: 'dashboard-single',
	components: {
		'apexchart': () => import('vue-apexcharts')
	},
	data() {
		return {
			DashboardSub: [],
			loadingGraph: false,
			dashboardMetrics: {
				average_order_amount: 0,
				total_invited_customers: 0,
				total_orders_this_month: 0,
				total_orders_today: 0,
				total_customer_visit_today: 0,
				total_sales_this_month: 0,
				total_published_articles: 0,
				best_x_selling_articles: [],
				customer_with_high_purchase: []
			},
			defaultGraphData: [],
			dateObject: {
				range: '',
				date: '',
			},
			graphData: {
				options: {},
				series: [
					// {
					//     name: 'Website Blog',
					//     type: 'area',
					//     data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
					// },
				]
			},
			loadingSub: true,
			totalTransaction: 0,
			totalSaving: 0,
			serverLineSeries: [
				{
					name: 'Website Blog',
					type: 'area',
					data: [44, 5, 4, 61, 22, 13, 20, 35, 52, 10, 27, 1]
				},
				{
					name: 'Social Media',
					type: 'area',
					data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
				}
			],
			options: {
				chart: {
					id: 'vuechart-example',
					height: 249,
					toolbar: {
						show: false
					}
				},
				xaxis: {
					categories: ['Jan', 'Feb', 'Mar', 'Apr', this.$t('may'), this.$t('june'), 'Jul', 'Aug', 'Sep', this.$t('oct'), 'Nov', 'Dec'],
					labels: {
						style: {
							colors: '#7987a1',
						},
					},
				},
				yaxis: {
					labels: {
						style: {
							colors: '#7987a1',
						},
						formatter: function (value) {
							var val = Math.abs(value)
							if (val >= 1000) {
								val = (val / 1000).toFixed(0) + 'K'
							}
							return val
						}
					},
				},
				plotOptions: {
					bar: {
						dataLabels: {
							enabled: false
						},
						columnWidth: '30%',
						endingShape: 'rounded',
					}
				},
				colors: ['#0589E8'],
				dataLabels: {
					enabled: false
				},
				grid: {
					show: false,
				},
				stroke: {
					show: true,
					width: 2,
					endingShape: 'rounded',
					colors: ['transparent'],
				},
				responsive: [{
					breakpoint: 576,
					options: {
						stroke: {
							show: true,
							width: 1,
							endingShape: 'rounded',
							colors: ['transparent'],
						},
					},
				}],
				fill: {
					opacity: 1
				},
				legend: {
					show: false,
					floating: true,
					position: 'top',
					horizontalAlign: 'left',
				},
				tooltip: {
					theme: 'dark'
				}
			}

		}
	},
	computed: {
		...mapGetters({
			GET_DASHBOARD_METRICS: 'portal/GET_DASHBOARD_METRICS'
		})
	},
	mounted() {
		this.getDashboardMetrics()
		window.Bus.$on('reload-locale-specific-component', () => {
			this.getDashboardMetrics()
		})
		setTimeout(function () {
			window.Bus.$emit('start-dashboard-tour')
		}, 20)
	},
	methods: {
		
		getDashboardMetrics() {
			this.loadingGraph = true
			let url = ''
			if (getCompanyId()) {url += `?company_id=${getCompanyId()}`}
			
			this.$store.dispatch('portal/getDashboardMetrics', {data: url})
			
				.then(res => {
					this.loadingGraph = false
					if (res.data.graph_revenue_monthly_data) {
						this.defaultGraphData = res.data.graph_revenue_monthly_data
						const obj = {}
						// obj.options = JSON.parse(JSON.stringify(this.options))
						this.options.xaxis.categories = ['Jan', 'Feb', 'Mar', 'Apr', this.$t('may'), this.$t('june'), 'Jul', 'Aug', 'Sep', this.$t('oct'), 'Nov', 'Dec']
						obj.options = JSON.parse(JSON.stringify(this.options))

						obj.series = []
						const data = Object.values(res.data.graph_revenue_monthly_data)
						const newObj = {}
						newObj.data = data
						newObj.name = this.$t('revenue')
						obj.series.push(newObj)
						this.graphData = {
							...this.graphData,
							options: obj.options,
							series: [newObj]
						}
					}
				})
				.catch(err => {
					this.loadingGraph = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
		},
		onChangeGraphData() {
			this.loadingGraph = true
			let query = `start_date=${this.dateObject.date[0]}&end_date=${this.dateObject.date[1]}`
			if (getCompanyId()) {
				query += `&company_id=${getCompanyId()}`
			}
			const payload = {
				query: query
			}
			this.$store.dispatch('portal/filterGraphData', payload)
				.then(res => {
					this.loadingGraph = false
					const unsetValues = new Array(12).fill(0)
					if (Object.keys(res.result).length > 0) {
						// Fill empty values
						const key = Object.keys(res.result)
						const value = Object.values(res.result)

						key.forEach((element, i) => {
							const index = this.options.xaxis.categories.indexOf(element.slice(0, 3))
							const digit = value[i]
							unsetValues[index] = digit
						})

						const obj = {}
						obj.data = unsetValues
						obj.name = 'revenue'
						this.graphData = {
							...this.graphData,
							...{series: [obj]}
						}
					} else {
						const obj = {}
						obj.data = unsetValues
						obj.name = 'revenue'
						this.graphData = {
							...this.graphData,
							...{series: [obj]}
						}
					}
				})
				.catch(_ => {
					this.loadingGraph = false
				})
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');

.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-theme-pink {
	background: #FEF1F5;
	border: 0.5px solid $theme-primary-color;
}

.bg-theme-blue {
	background: #303DB7;
	border: 0.5px solid #303DB7;
}

.bg-theme-yellow {
	background: #F1D132;
	border: 0.5px solid #F1D132;
}

.bg-theme-green {
	background: #12CA5B;
	border: 0.5px solid #12CA5B;
}

.text-tertiary {
	color: $theme-tertiary-color !important;
}

.text-secondary {
	color: $theme-secondary-color !important;
}

.text-color-theme {
	color: $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.bg-custom-theme-gray {
	background-color: #fff !important;
}

.unset-opa-increa-size {
	opacity: unset !important;
	font-size: 1.3rem;
}

.card-text-num {
	color: #7E3469 !important;
	font-weight: 800;
	float: right;
}

.card-header {
	height: unset !important;
}

.dashboard-theme-text {
	font-family: 'Mulish', sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	// line-height: 22px;
	letter-spacing: 0.30000001192092896px;
	text-align: center;
}

.card-side-4 {
	background: $production-theme;
}

.select-pad {
	padding-bottom: 0.3rem !important;
	padding-top: 0.3rem !important;
}
</style>
